export const group_to_titles = (cables) => {
  const groups = {};
  cables.map((cable) => {
    Object.keys(cable).map((key) => {
      // console.log(cable[key]);
      let title = cable[key].description;
      if (title === "Glance") {
        title = "Cable Information";
      }
      const value = cable[key].val;
      const units = cable[key].units;
      const screen_name = cable[key].screen_name;
      if (title in groups) {
        if (key in groups[title]) {
          groups[title][key] = [...groups[title][key], value];
        } else {
          groups[title][key] = [{ units: units, screen_name: screen_name }, value];
        }
      } else {
        groups[title] = {};
        groups[title][key] = [{ units: units, screen_name: screen_name }, value];
      }
    });
  });

  const grouporder = [
    "Cable Information",
    "System Operating Parameters",
    "Electrical Properties",
    "Thermal Calculations",
    "Mechanical Properties",
    "Geometry",
    // "Database Input",
    "input data",
    "Financial",
  ];

  const orderedGroups = {};
  grouporder.map((group) => {
    
    if (group in groups) {
      orderedGroups[group] = groups[group];
    }
  });

  return orderedGroups;
};