/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Popper,
  List,
  ListItem,
  ListItemText,
  Paper,
  Modal,
} from "@mui/material";
import { Search, Info, Close } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { onAuthStateChanged } from "firebase/auth";

import Select from 'react-select';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#5a5a5a",
    fontSize: "1rem",
    border: "1px solid lightgray",
  },
}));

const SearchText = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const prettierName = (name) => {
  // replace : and _ with space 
  // remove metadata and settings and design from name
  name = name.replace(/metadata./g, "")
  name = name.replace(/settings./g, "")
  name = name.replace(/design./g, "")
  name = name.replace(/environment./g, "")
  name = name.replace(/cable./g, "")
  name = name.replace(/financial./g, "")
  return name.replace(/[:_]/g, " ")
}


const SearchBar = ({ width = null, placeholder, infoTitle, items = [], handleFilter = null, isMulti = true }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [canSearch, setCanSearch] = useState(true); // 
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState(null);
  const [id_token, setIdToken] = useState(null);


  const handleOpenModal = () => {
    // setOpenModal(true);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredItems([]); // This line will close the Popper after selecting an item.
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickAway = () => {
    setFilteredItems([]);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      style={width ? {
        width: width,
      } : {}}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: 1.5,
        // m: 1,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ width: "100%" }}>
          <Select
            placeholder={<SearchText><Search fontSize="medium" /> {placeholder}</SearchText>}
            className="searchSpec"
            classNamePrefix="select"
            isClearable
            isSearchable
            isMulti={isMulti}
            onChange={(e) => handleFilter(e)}
            options={items}
          />
        </div>
      </ClickAwayListener>
    </Box>
  );
};

export default SearchBar;