import {
    doc,
    getDoc,
    setDoc,
    collection,
    query,
    where,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    startAt,
    endAt,
    startAfter,
    deleteDoc,
    getCountFromServer
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase';

export const getUserPermissions = async (uid) => {
    const user_doc_ref = doc(db, "permissions", uid)
    try {
        const doc_snap = await getDoc(user_doc_ref);
        const company_ids = doc_snap.data().permissions
        const permissions = {}
        for (let i = 0; i < company_ids.length; i++) {
            const company_doc_ref = doc(db, "organisations", company_ids[i])
            try {
                const company_doc_snap = await getDoc(company_doc_ref)
                const company_data = company_doc_snap.data()
                permissions[company_ids[i]] = {
                    cid: company_ids[i],
                    organisation: company_data.organisation,
                    queryables: company_data.queryables,
                }
            } catch (e) {
                // console.log(e)
            }
        }
        return permissions
    } catch (e) {
        // // console.log(e)
    }
}
export const getCompanyIds = (permissions) => {
    return Object.keys(permissions)
}
export const getCompanyNames = (permissions) => {
    return Object.values(permissions).map((company) => company.company_name)
}
export const getAvailableCableNames = (permissions, company_id) => {
    let names = []
    const org_queryables = permissions[company_id].queryables
    if (org_queryables.Name) {
        names = org_queryables.Name
    }
    return names
}




export const createQuery = (company_id, conditions, num_results, last_visible = null) => {
    // where a condition = where(filter, "==", option)
    const collection_path = `companies/${company_id}/cables`
    const collection_ref = collection(db, collection_path)
    // get ids of cables that match conditions
    // // console.log({ conditions, company_id })
    if (last_visible) {
        // // console.log("last visible")
        const q = query(collection_ref, orderBy("Name"), ...conditions, startAfter(last_visible), limit(num_results))
        return q
    } else {
        const q = query(collection_ref, orderBy("Name"), ...conditions, limit(num_results))
        return q
    }
}

export const getDocSnapFromQuery = async (query) => {
    try {
        const querySnapshot = await getDocs(query);
        // // console.log("Snapshot Success")
        return querySnapshot
    } catch (e) {
        // console.log(e)
    }
}

export const getNamesFromDocSnap = async (docSnap) => {
    const names = []
    try {
        docSnap.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            names.push(doc.data().Name)
        });
        // console.log("getting names")
        // console.log({ names })

    } catch (e) {
        // console.log(e)
    }
    return names
}

export const getCableByName = async (company_id, cable_name) => {
    // query by name
    // console.log(" getting cable by name ")
    // console.log({ company_id, cable_name })
    const collection_path = `cables`
    try {
        const q = query(collection(db, collection_path), where("metadata:name", "==", cable_name), where("cid", "==", company_id));
        const querySnapshot = await getDocs(q);

        const cables = []
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            cables.push(doc.data())
        });
        // console.log(cables[0])
        return cables[0]
    } catch (e) {
        console.log(e)
    }
}

export const getRecentCables = async (company_ids, max_cables) => {
    const cables = []
    for (let i = company_ids.length - 1; i > 0; i--) {
        const company_id = company_ids[i]
        const collection_path = `companies/${company_id}/cables`
        try {

            const q = query(collection(db, collection_path), limit(max_cables));
            const querySnapshot = await getDocs(q);

            // // console.log(querySnapshot.size)
            querySnapshot.forEach((doc) => {
                // // console.log(doc.id, " => ", doc.data());
                cables.push(doc.data())
            });
        } catch (e) {
            // console.log(e)
        }
    }
    return cables

}

export const uploadCable = async (blueprint, user, update = ()=>{}) => {
    // console.log("🚀 ~ uploadCable ~ cable:", cable)
    // console.log("🚀 ~ uploadCable ~ company_id:", company_id)
    // update cable list in document path
    // update cable in cable collection
    // if cable exists alert user and give option to update
    // console.log("loading cable")
    // console.log({ cable })

    // console.log("loading company doc", company_id)
    const permissions = await getUserPermissions(user.uid);
    const company_ids = getCompanyIds(permissions);
    const company_id = company_ids[1];
    const cable = blueprint 
    // if any are null return
    if (!company_id || !cable || !user) return;

    const company_doc_ref = doc(db, "organisations", company_id)
    const company_doc_snap = await getDoc(company_doc_ref)
    const company_data = company_doc_snap.data()
    // console.log("🚀 ~ uploadCable ~ company_data:", company_data)

    // ==================================================================================================   
    let cable_id = cable["id"] || uuidv4()
    if(cable["cid"] === "vekta"){
        alert("You cannot upload a cable with the name 'vekta'")
        cable_id = uuidv4()
    }

    // check if cable with same name exists

    const q = query(collection(db, "cables"), where("id", "==", cable_id), where("cid", "==", company_id));
    const querySnapshot = await getDocs(q);
    // console.log("🚀 ~ uploadCable ~ querySnapshot:", querySnapshot)

    if (querySnapshot.size > 0 || cable["id"] && cable["cid"] !== "vekta") {
        const confirm = window.confirm("Cable already exists, would you like to update it?", cable["id"])
        if (!confirm) return
    }
    const existing_cable = querySnapshot.docs[0]?.data()

   

    if (existing_cable && existing_cable.id) {
        cable_id = existing_cable.id
    }
    const new_cable = { ...cable }
    new_cable["name"] = cable["metadata:name"]
    new_cable["cid"] = company_id
    new_cable["organisation"] = company_data.organisation
    new_cable["Last edited by"] = user.email
    new_cable["id"] = cable_id

    // console.log({cable_id})
    const cable_ref = doc(db, `cables`, cable_id)

    // Set Cable
    try {
        await setDoc(cable_ref, new_cable)
    } catch (e) {
        console.log(e)
    }
    // console.log("🚀 ~ uploadCable ~ new_cable:", new_cable)




    // Get and or Update Queryables in Company Document
    //==================================================================================================

    let queryables = company_data.queryables



    if (Object.keys(queryables).length === 0) {
        // init queryables
        queryables = {}
        Object.keys(cable).map((key) => {
            queryables[key] = [cable[key]]
        })
    } else {
        // Update queryables
        Object.keys(cable).map((key) => {
            if (queryables[key]) {
                if (!queryables[key].includes(cable[key])) {
                    queryables[key].push(cable[key])
                }
            } else {
                queryables[key] = [cable[key]]
            }
        })

    }
    // add metadata to queryables
    queryables["cid"] = [company_id]
    queryables["organisation"] = [company_data.organisation]
    if (company_data.queryables["Last edited by"]) {
        if (!company_data.queryables["Last edited by"].includes(user.email)) {
            queryables["Last edited by"] = [...company_data.queryables["Last edited by"], user.email]
        }
    } else {
        queryables["Last edited by"] = [user.email]
    }

    setDoc(company_doc_ref, { queryables }, { merge: true })
    .then(() => {
        update();
    })


}

export const getQueryables = async (company_id) => {

    const company_doc_ref = doc(db, "organisations", company_id)
    const company_doc_snap = await getDoc(company_doc_ref)
    const company_data = company_doc_snap.data()
    return company_data.queryables
}

export const get_user_queryables = async (uid) => {
    try {
        const permissions = await getUserPermissions(uid)
        const company_ids = getCompanyIds(permissions)
        const queryables_promises = company_ids.map((company_id) => {
            return getQueryables(company_id)
        })
        const queryables_list = await Promise.all(queryables_promises)
        const merged_queryables = mergeQueryables(queryables_list)
        return merged_queryables
    } catch (e) {
        // console.log(e)
    }
}

// helps with dropdown options
export const watchAvailableCableNames = (company_id, callback) => {
    const company_doc_ref = doc(db, "organisations", company_id)
    const unsubscribe = onSnapshot(company_doc_ref, (doc) => {

        const queryables = doc.data().queryables
        let cable_names = []
        if (queryables.Name) {
            cable_names = queryables.Name
        }

        callback(cable_names)
    });
    return unsubscribe
}

export const mergeQueryables = (queryables_list) => {
    const result = {};
    queryables_list.forEach(obj => {
        // console.log({ obj })
        Object.keys(obj).forEach(key => {
            if (!result[key]) {
                // If the key doesn't exist in the result, simply assign it
                result[key] = [...obj[key]];
            } else {
                // If the key exists, combine the arrays and filter out duplicates
                result[key] = [...new Set([...result[key], ...obj[key]])];
            }
        });
    });
    // console.log("Merge Queryables Result:")
    return result;

}

export const getUserQueryables = async (uid) => {
    const permissions = await getUserPermissions(uid)
    const company_ids = getCompanyIds(permissions)
    const queryables_promises = company_ids.map((company_id) => {
        return getQueryables(company_id)
    })
    const queryables_list = await Promise.all(queryables_promises)
    const merged_queryables = mergeQueryables(queryables_list)
    return merged_queryables

}

export const watchUserQueryables = async (uid, setQueryables) => {

    const permissions = await getUserPermissions(uid)
    const company_ids = getCompanyIds(permissions)

    company_ids.forEach((company_id) => {
        const company_doc_ref = doc(db, "organisations", company_id)
        onSnapshot(company_doc_ref, async () => {
            const queryables = await getUserQueryables(uid)
            setQueryables(queryables)
        });
    })

}

export const generateConditions = (queryables, options) => {
    // // console.log("Generating Conditions")
    // // console.log({ queryables, options })
    const conditions_obj = {}
    Object.keys(options).map((filter) => {
        conditions_obj[filter] = options[filter]
    })

    if (!conditions_obj.cid) {
        const cid_list = queryables.cid
        conditions_obj.cid = cid_list
    }
    const conditions = []
    const readable_conditions = []
    Object.keys(conditions_obj).forEach((filter) => {
        // Assuming each key's value is an array of options you want to "or" together
        const q_options = conditions_obj[filter];

        // Check if options array has more than one value and the filter supports 'array-contains-any'
        if (q_options && q_options.length > 0) {
            // Use 'array-contains-any' if applicable
            conditions.push(where(filter, "in", q_options));
            readable_conditions.push(`${filter} in [${q_options.join(", ")}]`);
        }
    });
    // // console.log({ conditions })
    return conditions
}

export const getCables = async (conditions, last_visible = null, num_cables=7) => {
    let cable_query
    if (last_visible) {
        // console.log("start after")
        cable_query = query(collection(db, "cables"), ...conditions, startAfter(last_visible), limit(num_cables));
    } else {
        cable_query = query(collection(db, "cables"), ...conditions, limit(num_cables));
    }

    const querySnapshot = await getDocs(cable_query);
    // // console.log("Snapshot Success")
    const cables = []
    querySnapshot.forEach((doc) => {
        // // console.log(doc.id, " => ", doc.data());
        cables.push(doc.data())
    });
    return [cables, querySnapshot.docs[querySnapshot.docs.length - 1]]
}

export const getQueryCount = async (conditions) => {
    const coll = collection(db, "cables")
    const q = query(coll, ...conditions)
    const snapshot = await getCountFromServer(q)
    const count = snapshot.data().count
    // console.log("Count:", count)
    return count
}

export const deleteCable = async (cable) => {

    // update company doc queryables
    // const company_doc_ref = doc(db, "organisations", company_id)

    // TODO This does not trigger org doc watch currently
    const cable_ref = doc(db, `cables`, cable.id)
    // delete document
    try {
        await deleteDoc(cable_ref)
        // console.log("cable deleted")
    } catch (e) {
        console.log(e)
    }
}

export const downloadWorkspace = async (uid) => {
    const docRef = doc(db, "workspaces", uid);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return docSnap.data()
        } else {
            // console.log("No such document!");
        }
    } catch (e) {
        // console.log(e)
    }
}


export const updateWorkspace = async (user, workspace) => {
    try {
        const docRef = doc(db, "workspaces", user.uid);
        await setDoc(docRef, workspace, { merge: true });
    } catch (e) {
        console.log(e)
    }
}