import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { group_to_titles } from "./helperFunctions";
import TableRender from "./TableRender";
import FrontPage from "./FrontPage";
import SecondPage from "./SecondPage";

// Styled components
const OuterBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ReportContainer = styled.div`
  position: relative;
  margin-top: 0px;
  padding: 16px;
`;

const ReportRender = ({
  logo,
  settings,
  previewPDF,
  cables,
  includedSpecs,
}) => {
  const [tables, setTables] = useState(null);

  useEffect(() => {
    // console.log(logo);
  }, [logo]);

  useEffect(() => {
    if (cables.length > 0) {
      const groups = group_to_titles(cables);
      let temp = {};

      // console.log(groups);

      // Filter out the cable specs that the user does not want in the PDF
      Object.keys(groups).map((group) => {
        temp[group] = {};

        Object.keys(groups[group]).map((specs) => {
          if (includedSpecs.indexOf(specs) > -1) {
            // console.log(specs);
            // console.log(group);
            temp[group][specs] = groups[group][specs];
          }
        });
      });

      let cable_information = null;

      // Combining database inputs into the geometry as they are not included otherwise
      if (temp["Database Input"]) {
        temp["Geometry"] = { ...temp["Geometry"], ...temp["Database Input"] };
      }

      if (temp["Glance"]) {
        cable_information = {
          ...temp["Glance"],
          ...temp["input data"],
          ...temp["Financial"],
        };
        delete cable_information["Glance"];
      }

      setTables([
        ...(cable_information
          ? [
              <TableRender
                data={cable_information}
                // sortOrder={[
                //   "cable_name",
                //   "cable_type",
                //   "core_material",
                //   "core_size",
                //   "system_voltage",
                //   "nominal_voltage",
                //   "maximum_voltage",
                //   "insulation_material",
                //   "core_system_type",
                //   "optical_fibre_core_number",
                //   "cost",
                //   "nn_cost",
                // ]}
                title="Cable Information"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Cable Information"]
          ? [
              <TableRender
                data={temp["Cable Information"]}
                title="Cable Information"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["System Operating Parameters"]
          ? [
              <TableRender
                data={temp["System Operating Parameters"]}
                // sortOrder={[
                //   "system_frequency",
                //   "cable_environment_type",
                //   "ground_environment_thermal_condition",
                //   "air_environment_thermal_condition",
                //   "burial_depth",
                //   "dry_ground_thermal_resistivity",
                //   "ground_thermal_resistivity",
                //   "core_temp",
                //   "metallic_screen_temp",
                //   "ground_temp",
                //   "critical_ground_temperature",
                //   "air_temperature",
                // ]}
                title="System Operating Parameters"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Electrical Properties"]
          ? [
              <TableRender
                data={temp["Electrical Properties"]}
                title="Electrical Properties"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Thermal Calculations"]
          ? [
              <TableRender
                data={temp["Thermal Calculations"]}
                title="Thermal Specifications"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Mechanical Properties"]
          ? [
              <TableRender
                data={temp["Mechanical Properties"]}
                title="Mechanical Properties"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Geometry"]
          ? [
              <TableRender
                data={temp["Geometry"]}
                title="Geometry"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["Financial"]
          ? [
              <TableRender
                data={temp["Financial"]}
                title="Financial"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
        ...(temp["input data"]
          ? [
              <TableRender
                data={temp["input data"]}
                title="Input Cable Specification"
                settings={settings}
                logo={logo}
                cables={cables}
              />,
            ]
          : []),
      ]);
    }
  }, [cables, logo, settings, includedSpecs]);

  return (
    <OuterBox
      style={{
        position: !previewPDF ? "fixed" : "relative",
        left: !previewPDF ? "999999px" : "0px",
        width: "100%",
      }}
    >
      <ReportContainer
        id="reportContainer"
        style={{
          position: "absolute",
          height: "100%",
          margin: "auto",
          left: "50%",
          top: "0px",
          transform: "translate(-50%, 0px)",
        }}
      >
        <FrontPage logo={logo} settings={settings} />
        <SecondPage logo={logo} settings={settings} />
        {tables}
      </ReportContainer>
    </OuterBox>
  );
};

export default ReportRender;
