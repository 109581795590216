import React, { useState } from "react";
import CableDetail from "../../../media/cableDetails.png";
import CableSettings from "../../../media/cableSettings.png";
import CableDatasheet from "../../../media/pdfOutput.png";
import IconTwo from "../../../media/CableIcon_Vblue.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import PopupVideo from "../modals/PopupVideo";

function ServicesHomeEight() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1500,
    cssEase: "linear",
  };

  const [showVideo, setVideoValue] = useState({ state: false, src: null });
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue({ state: false, src: null });
  };

  const handleFullScreenImage = (img) => {
    setVideoValue({ state: true, src: img });
  };

  return (
    <>
      <section
        className="appie-services-2-area appie-services-8-area pt-185 pb-55"
        id="service"
      >
        <div className="container">
          <div className="row align-items-end">
            <div>
              <div className="appie-section-title">
                <h3 className="appie-title">
                  The comprehensive cable solution
                </h3>
                <p>
                  Vekta Cables is a secure, cloud-based platform designed by
                  industry experts to revolutionise your cable RFP and design management
                  workflow. Our comprehensive tool empowers you with: <br></br>
                  <br></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Accurate pricing data, reducing RFQ response time and costs.{" "}
                  <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Custom cable design and specification capabilities. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Extensive database of over 600 cables, with advanced search
                  filters. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Automatic pricing calculations based on raw materials,
                  manufacturing costs and market data. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Comprehensive datasheet generation, including electrical,
                  mechanical and environmental parameters. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Compatibility with industry standards like IEC 60286, CIGRE TB
                  908 and TB 880. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Export options for datasheets in multiple formats [CSV, PDF,
                  DigSilent PowerFactory] <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div
                    className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <div className="icon">
                      <i>
                        <img src={IconTwo} width={30} />
                      </i>
                    </div>
                    <h4 className="title">Design</h4>
                    <p>
                      Build and save your own custom cables securely. Leverage
                      our recommended default value or input detailed
                      manufacture geometry data for improved accuracy
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                    data-wow-duration="2000ms"
                    data-wow-delay="400ms"
                  >
                    <div className="icon">
                      <i className="fa-solid fa-magnifying-glass" />
                    </div>
                    <h4 className="title">Search</h4>
                    <p>
                      Utilise our advanced filtering system to search through
                      our extensive cable database by keywords or specifications
                      that matter most to you. Quickly view cross-sectional
                      diagrams and cable details.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <div className="icon">
                      <i className="fa-solid fa-money-bill-wave" />
                    </div>
                    <h4 className="title">Price</h4>
                    <p>
                      Calculate pricing estimates for class 3 and above onshore
                      and offshore HV cables based on market prices, manufacture
                      costs and historical data from trusted sources like LME,
                      dating back to 2015.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <div className="icon">
                      <i className="fa-solid fa-file-invoice" />
                    </div>
                    <h4 className="title">Datasheets/Reports</h4>
                    <p>
                      Generate comprehensive, customisable cable datasheets
                      containing key parameters like environmental, electrical,
                      mechanical, storage, and handling information in just
                      seconds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(CableDetail)}>
                    <label>Cable Design</label>
                    <img src={CableDetail} />
                  </div>
                  <div onClick={() => handleFullScreenImage(CableSettings)}>
                    <label>Environmental Settings</label>
                    <img src={CableSettings} />
                  </div>
                  <div onClick={() => handleFullScreenImage(CableDatasheet)}>
                    <label>Datasheet Output</label>
                    <img src={CableDatasheet} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {showVideo.state && (
          <PopupVideo
            imageSrc={showVideo.src}
            handler={(e) => handleShowVideo(e)}
          />
        )}
      </section>
    </>
  );
}

export default ServicesHomeEight;
