import React, { useEffect, useState } from "react";
import logo from "../../../media/VEKTACABLES_white.png";
import whiteLogo from "../../../media/VEKTACABLES_white.png";
import StickyMenu from "./StickyMenu.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase.js";

function HeaderHomeFour({
  action,
  setLoginSelected,
  setOpenProfile,
  homePage,
  setHomePage,
}) {
  const [user, loading, error] = useAuthState(auth);
  const [useLogo, setUseLogo] = useState(logo);

  useEffect(() => {
    document.getElementById("landing-page").addEventListener("scroll", () => {
      const element = document.querySelector(".appie-sticky");
      if (element) {
        if (
          document.getElementById("landing-page").scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          element.classList.add("sticky");
          setUseLogo(whiteLogo);
        } else {
          element.classList.remove("sticky");
          setUseLogo(logo);
        }
      }
    });
  }, []);

  return (
    <>
      <header
        id="appie-sticky"
        className="appie-header-area appie-header-4-area appie-sticky"
      >
        <div className="container">
          <div className="header-nav-box header-nav-box-5">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                <div className="appie-logo-box">
                  {homePage ? (
                    <a href="#vekta_cables_top">
                      <img src={useLogo} alt="" />
                    </a>
                  ) : (
                    <a href="/">
                      <img src={useLogo} alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                <div className="appie-header-main-menu"></div>
              </div>
              <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                <div className="appie-btn-box text-right">
                  {!user ? (
                    <a
                      className="login-btn"
                      href="#"
                      onClick={() => {
                        console.log("set login to true and open popup");
                        setLoginSelected(true);
                      }}
                    >
                      <i className="fa-solid fa-user"></i>Login
                    </a>
                  ) : (
                    <a
                      className="login-btn"
                      href="#"
                      onClick={() => {
                        auth.signOut();
                      }}
                    >
                      <i className="fa-solid fa-user"></i>Sign Out
                    </a>
                  )}
                  {homePage ? (
                    <a
                      className="main-btn ml-30"
                      href="#"
                      onClick={() => setHomePage(false)}
                    >
                      Launch Tool
                    </a>
                  ) : (
                    <a className="main-btn ml-30" href="#pricing-cards">
                      Pricing
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeFour;
