import { Divider, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Cable, CableCollection, PrimeButton, Row8pxGap, Section, SectionFooter, SectionFooterLeft, SecButton } from "../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGear,
    faInfoCircle,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
// import CableModal from "../../common/CableModal";
import BpModal from "../bp/BpModal";


import CableRow from "../../media/tutorials/cableAnalysis.png"
import BatchCableSettings from "../../media/tutorials/batchcablesettings.gif"

import Joyride, { STATUS } from 'react-joyride';


const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
        props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

function Analysis({
    user,
    toAnalyse,
    setToAnalyse,
    setRunAPI,
    activeWorkspace,
    setActiveWorkspace,
}) {
    const [cableCreate, setCableCreate] = useState({ open: false, type: "Close" })
    const [defaultSettings, setDefaultSettings] = useState({ open: false })
    const [settings, setSettings] = useState({ open: false });
    const [selected, setSelected] = useState(null);
    const [selectedCable, setSelectedCable] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [globalSettings, setGlobalSettings] = useState({})
    const [runJoyride, setRunJoyride] = useState(false)
    const [{ widgetRun, steps }, setWidgetJoyride] = useState({
        steps: [
            {
                content: (
                    <div>
                        <h2>Batch Analysis</h2>
                        <p>Apply the same settings to a number of number of cables.</p>
                    </div>),
                locale: { skip: <strong aria-label="skip">SKIP</strong> },
                placement: 'right',
                target: '.section_analysis',
                disableBeacon: true
            },
            {
                title: "Cables to Analyse",
                content:
                    <div>
                        <img width={"100%"} src={CableRow} />
                        <p>
                            The cables for analysis will be listed here when added.
                        </p>
                    </div>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "bottom",
                target: "#cables_toAnalyse",
                styles: {
                    options: {
                        width: 500,
                    }
                },
            },
            {
                title: "Global Cable Settings",
                content:
                    <div>
                        <img width={"100%"} src={BatchCableSettings} />
                        <p>
                           Add global settings to all cables to be analysed. This will override the individual cable settings.
                           The red box indicates settings that should be edited next based on what has been filled in so far. 
                           Remember to click "Apply to All". 
                        </p>
                    </div>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "bottom",
                target: ".global_settings",
                styles: {
                    options: {
                        width: 500,
                    }
                },
            },
            {
                title: "Run Analysis",
                content: <p>
                    Click this when you are ready to analyse the cables loaded. The results will appear in the "Results" panel.
                </p>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "top",
                target: "#run_analysis"
            },
        ]
    });

    useEffect(() => {
        setToAnalyse(toAnalyse => {
            if (toAnalyse.length === 0) {
                return []
            }
            console.log({ toAnalyse })
            const newToAnalyse = toAnalyse.map(callable => {
                return {
                    ...callable,
                    cable: {
                        ...callable.cable,
                        ...globalSettings
                    }
                }
            })
            console.log({ newToAnalyse })
            return newToAnalyse
            // return toAnalyse
        })
    }, [globalSettings])

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyride(false)
        }
    };

    const handleTutorial = () => {
        setRunJoyride(true)
    }

    const handleRemove = (index) => {
        let newToAnalyse = [...toAnalyse]
        newToAnalyse.splice(index, 1)
        setToAnalyse(newToAnalyse)
    }

    const handleInfo = (toAnalyse, index) => {
        console.log("handleInfo")
        const cable = toAnalyse[index].cable
        console.log(cable)
        setSelectedIndex(index)
        setSelectedCable(cable)
        setCableCreate({ open: true, type: "Close" })
    }

    const handleSettings = (toAnalyse, index) => {
        console.log("handleSettings")
        const cable = toAnalyse[index].cable
        console.log(cable)
        setSelectedIndex(index)
        setSelectedCable(cable)
        setSettings({ open: true })
    }

    const handleDefaultSettings = () => {
        console.log("handleDefaultSettings")
        setDefaultSettings({ open: true })
    }


    const handleClose = () => {
        setSelected(null)
        setCableCreate({ open: false, type: "Close" })
        setSettings({ open: false })
        setDefaultSettings({ open: false })
    }



    const renderCablesToAnalyse = (toAnalyse) => {
        const cablesToAnalyse = []
        for (let i = 0; i < toAnalyse.length; i++) {
            const callable = toAnalyse[i]
            const cable = callable.cable
            cablesToAnalyse.push(
                [<Cable key={`Cables To Analyse ${i}`}>
                    <label className="cableName">{cable["metadata:name"]}</label>

                    <Row8pxGap>
                        <StyledIconButton title="Remove Cable" onClick={() => handleRemove(i)}>
                            <FontAwesomeIcon color="black" icon={faTrash} />
                        </StyledIconButton>

                        <StyledIconButton title="Cable Details" onClick={() => handleInfo(toAnalyse, i)}>
                            <FontAwesomeIcon color="black" icon={faInfoCircle} />
                        </StyledIconButton>

                        <StyledIconButton title="Run Settings" onClick={() => handleSettings(toAnalyse, i)} className="settingsButton">
                            <FontAwesomeIcon color="black" icon={faGear} />
                        </StyledIconButton>
                    </Row8pxGap>
                </Cable>,
                ...i < 4 ? [<Divider />] : [],
                ]
            )
        }
        return cablesToAnalyse
    }

    useEffect(() => {
        console.log({toAnalyse})
    }, [toAnalyse])


    const renderModals = ( toAnalyse,selected,selectedCable,settings,defaultSettings,globalSettings, selectedIndex) =>{
        const selected_cable = toAnalyse[selectedIndex]?.cable || {}
        console.log({selected_cable})
        return <>
            <Modal
                open={cableCreate.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <BpModal handleClose={handleClose} type={cableCreate.type} modalName={"Cable Details"} cable={selectedCable} user={user} source="batch" />
            </Modal>

            <Modal
                open={settings.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <BpModal handleClose={handleClose} type="Apply" modalName={"Cable Settings"} cable={selected_cable} user={user} source="cable_settings" update_cable_settings={(partial) => {
                    const index = selectedIndex
                    console.log({ index, partial })
                    setToAnalyse(toAnalyse => {
                        const newToAnalyse = [...toAnalyse]
                        newToAnalyse[index].cable = {
                            ...newToAnalyse[index].cable,
                            ...partial
                        }
                        console.log({newToAnalyse})
                        return newToAnalyse
                    })
                    console.log({ index, partial })
                }} />
            </Modal>


            <Modal
                open={defaultSettings.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {/* <DefaultSettingsModal 
                    handleClose={handleClose} 
                    selected={selected} 
                    toAnalyse={toAnalyse} 
                    setToAnalyse={setToAnalyse} 
                    activeWorkspace={activeWorkspace}
                    setActiveWorkspace={setActiveWorkspace}
                /> */}
                <BpModal handleClose={handleClose} type="Apply to All" modalName={"Global Cable Settings"} cable={globalSettings} source="cable_settings" update_global_settings={(settings) => {
                    // settings override
                    setGlobalSettings(settings)
                }} />
            </Modal>
        </>
    }
    return (
        <>
            <Joyride
                continuous
                callback={handleJoyrideCallback}
                run={runJoyride}
                steps={steps}
                showSkipButton
                showProgress
                styles={{
                    options: {
                        primaryColor: "#009ec6",
                        zIndex: 10000,
                    },
                }}
            />
            <Section style={{ height: "calc(47% - 16px)" }} className="section_analysis">
                <header style={{ position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h1>Batch Analysis</h1>
                            <StyledIconButton title="Analysis Guidance" onClick={handleTutorial}>
                                <FontAwesomeIcon icon={faCircleQuestion} />
                            </StyledIconButton>
                        </div>
                        <div className="global_settings">
                            <SecButton title="Global Cable Run Settings" onClick={() => {
                                handleDefaultSettings()
                            }}>
                                Settings
                            </SecButton>

                        </div>
                    </div>

                    <Divider sx={{ bgcolor: "black", marginTop: "16px" }} />
                    {/* <ClearAll className="clearAll" onClick={() => setToAnalyse([])}>Clear All</ClearAll> */}
                </header>


                <CableCollection style={{ overflowY: "scroll" }} id="cables_toAnalyse">
                    {renderCablesToAnalyse(toAnalyse)}
                </CableCollection>

                <SectionFooter>
                    <SectionFooterLeft>
                        Cables: {toAnalyse.length} / 5
                    </SectionFooterLeft>
                    {/* <SectionFooterRight id="run_analysis"> */}
                    {/* disabled={toAnalyse.length === 0} */}
                    <PrimeButton id="run_analysis" title="Run Batch Analysis" onClick={() => {
                        // get "datasheet_secret_button" by id and click it
                        document.getElementById("datasheet_secret_button").click()

                    }}>Run</PrimeButton>
                    {/* </SectionFooterRight> */}
                </SectionFooter>
            </Section>
                    {
                        renderModals(toAnalyse,selected,selectedCable,settings,defaultSettings,globalSettings,selectedIndex)
                    }

        </>
    )
}

export default Analysis