import { Divider, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import "./override.css";
import {
  Section,
  CableCollection,
  Cable,
  SectionFooter,
  SectionFooterRight,
  CableName,
  ActionButtons,
  MeButton,
  FilterBar,
  SecretButton,
  SectionFooterLeft,
  PrimeButton,
  SecButton,
  Row8pxGap,
} from "../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  faAdd,
  faInfoCircle,
  faQuestionCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

// import CableModal from "../../common/CableModal";
import FilterModal from "./FilterModal";

import {
  deleteCable,
  downloadWorkspace,
  generateConditions,
  getCableByName,
  getCables,
  getQueryCount,
  getUserPermissions,
  watchUserQueryables,
} from "../../functions/functions";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../firebase";
import { CalendarMonthSharp, Delete } from "@mui/icons-material";

import { v4 as uuidv4 } from "uuid";

import CableDetails from "../../media/tutorials/Cable_Details.gif";
import CableFilter from "../../media/tutorials/Cable_Filters.gif";
import CableRow from "../../media/tutorials/cableRow.png";

import Joyride, { STATUS } from "react-joyride";
import BpModal from "../bp/BpModal";

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function Collection({
  user,
  toAnalyse,
  setToAnalyse,
  activeWorkspace,
  setActiveWorkspace,
}) {
  const [cableCreate, setCableCreate] = useState({ open: false, type: "Save" });
  const [selectedCable, setSelectedCable] = useState(null);
  const [filterModal, setFilterModal] = useState(false);

  const [cableCollection, setCableCollection] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const [lastVisible, setLastVisible] = useState(null);

  const [permissions, setPermissions] = useState(null);

  const [saveTrigger, setSaveTrigger] = useState(false);

  const [cables, setCables] = useState([]);
  const [queryables, setQueryables] = useState([]);
  const [collection, setCollection] = useState(null);
  const [count, setCount] = useState(0);

  const countRef = useRef(0);
  const cablesLengthRef = useRef(0);

  useEffect(() => {
    countRef.current = count;
    cablesLengthRef.current = cables.length;
  }, [cables, count]);

  const [runJoyride, setRunJoyride] = useState(false);
  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Cable Collection</h2>
            <p>
              Visualise and explore all the Vekta Group cables or create your
              own
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right",
        target: ".section_collection",
        disableBeacon: true,
      },
      {
        title: "Cable Filtering",
        content: (
          <div>
            <img width={"100%"} src={CableFilter} />
            <p>
              Searching for a specific cable? Apply filter in this popup to only
              show those cables. <br></br>
              The "Results" value in the bottom left indicates the amount of
              cables that make the filter applied.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right",
        target: "#cable_filtering",
      },
      {
        title: "Cable Collection",
        content: (
          <div>
            <img width={"100%"} src={CableRow} />
            <p>
              Each row represents a cable in your personal collection or Vekta
              Groups <br></br>
              The cable name is split like this: <br></br>
              <br></br>[ Type ] [ Voltage ] [ Size ] [ Material ] [ Owner ]
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: "#CableCollectionScroll",
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: "Create Cable",
        content: (
          <div>
            <img width={"100%"} src={CableDetails} />
            <p>
              Create custom cables and add them to your personal collection.
              <br></br>
              <br></br>
              Fill in the information in the popup after clicking this button
              and save. Any missing required information will be highlighted
              before saving.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: "#cable_create",
      },
    ],
  });

  const [filterNames, setFilterNames] = useState([]);
  const [options, setOptions] = useState(activeWorkspace.collection.options);
  const [selectedFilters, setSelectedFilters] = useState(
    activeWorkspace.collection.selectedFilters
  );

  const [conditions, setConditions] = useState([]);

  const [scrollTimedOut, setScrollTimedOut] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleAddCable = async (cable, activeWorkspace) => {
    console.log("adding cable to analysis");
    if (toAnalyse.indexOf(cable) === -1) {
      setToAnalyse((toAnalyse) => {
        // TODO: TEMP CHANGE TO DO NKT TESTS
        if (toAnalyse.length < 5) {
          //console.log(cable["Default system voltage"])
          //console.log(activeWorkspace)
          //const settings = {
          //    ...activeWorkspace.analysis.defaultSettings,
          //    "system_voltage": cable["Default system voltage"],
          //    "max_voltage": cable["Maximum voltage"]
          //}
          //console.log(settings)
          return [...toAnalyse, { cable }];
        }

        return [...toAnalyse];
      });
    }
  };

  const handleInfo = async (cable) => {
    setSelectedCable(cable);
    setCableCreate({ open: true, type: "Save" });
  };

  const handleCloseCableModal = () => {
    setSelectedCable(null);
    setCableCreate({ open: false, type: "Save" });
  };

  const handleFilter = () => {
    setFilterModal(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModal(false);
  };

  const updateCables = async (
    conditions,
    last_visible = null,
    num_cables = 7,
    scroll = false
  ) => {
    // console.log({conditions})
    try {
      const cable_length = cablesLengthRef.current;
    //   let q_count = 0;

    //   try {
        const q_count = await getQueryCount(conditions);
        setCount(q_count);
    //   } catch (e) {
        // console.log(e);
    //   }

      if (cable_length + num_cables > q_count && scroll) {
        num_cables = q_count - cable_length;
        console.log({ num_cables, cable_length, q_count });
      }

      const [filtered_cables, new_last_visible] = await getCables(
        conditions,
        last_visible,
        num_cables
      );
      // consol.log("🚀 ~ last_visible:", new_last_visible)
      // consol.log("🚀 ~ cables:", filtered_cables)
      if (last_visible) {
        setCables((prev_cables) => [...prev_cables, ...filtered_cables]);
      } else {
        setCables(filtered_cables);
      }
      setLastVisible(new_last_visible);

      setLoadedOnce(true);
    } catch (e) {
      if (loadedOnce) {
        console.log(e);
      } else {
        // alert(e)
        // console.log("Didnt load trying again")
        setTimeout(() => {
          updateCables(conditions, last_visible, num_cables);
        }, 12000);
      }
    }
  };

  const initPermissions = async () => {
    const user_permissions = await getUserPermissions(user.uid);
    // // consol.log("🚀 ~ user_permissions:", user_permissions)
    setPermissions(user_permissions);
    watchUserQueryables(user.uid, setQueryables);
  };

  const handleDelete = async (cable, index) => {
    try {
      const confirm = window.confirm(
        "You are about to delete a cable from your collection, Are you sure?"
      );
      if (!confirm) return;
      await deleteCable(cable);
      // remove cable from collection by index
      const new_cables = [...cables];
      new_cables.splice(index, 1);
      setCables(new_cables);
      console.log("Cable Deleted");
    } catch (e) {
      console.log(e);
    }
    updateCables(conditions, lastVisible);
  };

  useEffect(() => {
    // TODO - Set up snapshot to read collection from firestore
    //        And populate the cableCollection useState
    // Get 10 most recent cables
    if (!user) return;
    initPermissions();
  }, [user]);

  useEffect(() => {
    console.log("UPDATE CABLES - please?");
    if (user && queryables && options) {
      handleUpdateCables();
    }
  }, [queryables, options, user]);

  const handleUpdateCables = () => {
    const new_conditions = generateConditions(queryables, options);
    updateCables(new_conditions, null, 20);
    setConditions(new_conditions);
  };

  // Persistent debounced scroll handler using useCallback
  const handleScroll = useCallback(
    debounce((element) => {
      if (element.scrollHeight - element.scrollTop < element.clientHeight + 3) {
        if (scrollTimedOut) {
          console.log("Scroll Timed Out");
        } else {
          console.log("Scrolling End");
          updateCables(conditions, lastVisible, 7, true);
          setScrollTimedOut(true);
          setTimeout(() => {
            setScrollTimedOut(false);
          }, 100);
        }
      }
    }, 100),
    [scrollTimedOut, conditions, lastVisible]
  ); // debounce delay of 100ms

  useEffect(() => {
    const element = document.getElementById("CableCollectionScroll");
    if (!element) return;

    const debouncedScrollHandler = (e) => handleScroll(e.target);

    // Add event listener when the component mounts
    element.addEventListener("scroll", debouncedScrollHandler);

    // Cleanup function to remove the event listener
    return () => {
      element.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [handleScroll]); // Dependencies for effect

  useEffect(() => {
    const new_collection = [
      ...cables.map((cable, index) => [
        <Cable title={cable["metadata:name"] || cable.Name} key={uuidv4()}>
          <label className="cableName">
            {cable["metadata:name"] || cable.Name}
          </label>

          <Row8pxGap>
            {cable.cid !== "vekta" ? (
              <StyledIconButton
                title="Delete"
                onClick={() => handleDelete(cable, index)}
              >
                <FontAwesomeIcon color="black" icon={faTrash} />
              </StyledIconButton>
            ) : null}
            <StyledIconButton
              title="Cable Details"
              onClick={() => handleInfo(cable)}
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>

            <StyledIconButton
              title="Add Cable"
              onClick={() => handleAddCable(cable, activeWorkspace)}
            >
              <FontAwesomeIcon color="black" icon={faAdd} />
            </StyledIconButton>
          </Row8pxGap>
        </Cable>,
        <Divider />,
      ]),
    ];

    // if(cables.length > count){
    //     new_collection.push( <div style={{ marginTop: "16px" }}>Scroll to load more...</div> )
    // }

    setCollection(new_collection);
  }, [cables]);

  useEffect(() => {}, [cables, count]);

  return (
    <>
      {/* <SecretButton id="SecretFilterButton" onClick={() => {
                // // consol.log("resetting cables")
                setCables([])
                setLastVisible(null)
            }} /> */}

      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
            margin: 0,
          },
        }}
      />
      <Section
        className="section_collection"
        style={{ height: "calc(60% - 16px)", marginBottom: "16px" }}
      >
        <header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>Cable Collection</h1>
              <StyledIconButton
                title="Collection Guidance"
                onClick={handleTutorial}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </StyledIconButton>
            </div>
            <div id="cable_filtering">
              {/* <StyledIconButton title="Filter" onClick={handleFilter}> */}
              <SecButton title="Cable Filters" onClick={handleFilter}>
                Filter
              </SecButton>
              {/* </StyledIconButton> */}
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "16px" }} />
        </header>
        <CableCollection id="CableCollectionScroll">
          {collection}
        </CableCollection>

        <SectionFooter>
          <SectionFooterLeft>Results: {count}</SectionFooterLeft>
          {/* <SectionFooterRight id="cable_create"> */}
          <PrimeButton
            id="cable_create"
            title="Create New Cable"
            onClick={() => setCableCreate({ open: true, type: "Save" })}
          >
            Create
          </PrimeButton>
          {/* <MeButton >Create</MeButton> */}
          {/* </SectionFooterRight> */}
        </SectionFooter>
      </Section>

      <Modal
        open={cableCreate.open}
        onClose={handleCloseCableModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*<CableModal handleClose={handleCloseCableModal} type={cableCreate.type} cable={selectedCable} user={user} update={handleUpdateCables} /> */}
        <BpModal
          handleClose={handleCloseCableModal}
          type={cableCreate.type}
          modalName={"Cable Details"}
          cable={selectedCable}
          user={user}
          update={handleUpdateCables}
          source="collection"
        />
      </Modal>
      <Modal
        open={filterModal}
        onClose={handleCloseFilterModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FilterModal
          user={user}
          handleClose={handleCloseFilterModal}
          options={options}
          setOptions={setOptions}
          queryables={queryables}
          filterModal={filterModal}
          filterNames={filterNames} // defaultable
          selectedFilters={selectedFilters} // defaultable
          setFilterNames={setFilterNames}
          setSelectedFilters={setSelectedFilters}
          count={count}
          activeWorkspace={activeWorkspace}
          setActiveWorkspace={setActiveWorkspace}
        />
      </Modal>
    </>
  );
}

export default Collection;
