import React, { useState } from "react";
import heroThumb from "../../../media/LaptopDemo.png";
import VC_LOGO from "../../../media/VEKTACABLES_white.png";
import PopupVideo from "../modals/PopupVideo.jsx";
import { SecButton } from "../../../styles/styles.js";
import styled from "styled-components";

const LandingSecBtn = styled(SecButton)`
  width: 200px;
  padding: 0 30px;
  font-size: 15px;
  line-height: 45px;
  &:hover {
    color: #000;
    background: #009ec629;
  }
`;

function HeroHomeFour({ setDemoSelected }) {
  const [showVideo, setVideoValue] = useState(false);
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue(!showVideo);
  };
  return (
    <>
      {showVideo && (
        <PopupVideo
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={(e) => handleShowVideo(e)}
        />
      )}
      <section className="appie-hero-area appie-hero-5-area appie-hero-3-area">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-lg-10">
              <div className="appie-hero-content text-center">
                <p id="vekta_cables_top" style={{ opacity: "1" }}>
                  Effortless cable sourcing awaits with
                </p>
                <h1 className="appie-title">
                  {/* Mobile Interaction Designs of January 2022{" "} */}
                  {/* <img src={VC_LOGO} /> */}
                  VEKTA CABLES
                </h1>
                <p style={{ opacity: "1" }}>
                  Our innovative software gives you complete control over
                  high-voltage cable pricing and technical data. Experience the
                  future of effortless cable RFQ and design management today!
                </p>
                <p style={{ opacity: "1" }}>
                  Powered By:{" "}
                  <strong>
                    <a
                      href="https://vektagroup.com"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      Vekta Group
                    </a>
                  </strong>
                </p>
                <div
                  className="hero-btns"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <LandingSecBtn onClick={() => setDemoSelected(true)}>
                    Book a Meeting
                  </LandingSecBtn>
                  <a
                    style={{ width: "200px" }}
                    className="main-btn"
                    href="#pricing-cards"
                  >
                    Start free trial
                  </a>
                  {/* <a
                                        onClick={(e) => handleShowVideo(e)}
                                        className="appie-video-popup"
                                        href="https://www.youtube.com/watch?v=EE7NqzhMDms"
                                    >
                                        <i className="fas fa-play"></i> Watch the trailer
                                    </a> */}
                </div>
                <div
                  className="thumb mt-80 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <img src={heroThumb} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHomeFour;
