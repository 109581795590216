export const getCurrentDate = () =>{
    // This function will format the date as 'day month year'
    const formatDate = (date) => {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    return formatDate(new Date())

  }