import React, { useEffect, useState } from "react";
import { getCheckoutUrl, getPortalUrl } from "../helperFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";

import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function PricingHomeOne({
  className,
  setLoginSelected,
  setDemoSelected,
  toolInfo,
  homePage,
  setHomePage,
}) {
  const [toggleSwitch, setSwitchValue] = useState(true);
  const [user, loading, error] = useAuthState(auth);

  const [loadingCheckout, setLoadingCheckout] = useState({
    month: false,
    year: false,
    monthTrial: false,
    yearTrial: false,
  });

  const handleToolBuy = async (tool, interval, trial = false) => {
    console.log(tool);
    if (user) {
      setLoadingCheckout((loadingCheckout) => ({
        ...loadingCheckout,
        [interval + (trial ? "Trial" : "")]: true,
      }));

      console.log(trial);

      const checkoutUrl = await getCheckoutUrl(
        toolInfo[tool[0]].prices[interval].id,
        trial
      );
      console.log(checkoutUrl);
      window.location.href = checkoutUrl.url;

      setLoadingCheckout({
        month: false,
        year: false,
        monthTrial: false,
        yearTrial: false,
      });
      return;
    }

    setLoginSelected(true);
  };

  useEffect(() => {
    console.log(loadingCheckout);
  }, [loadingCheckout]);

  return (
    <>
      <section
        id="pricing-cards"
        className={`appie-pricing-area pt-90 pb-90 ${className || ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Choose your plan</h3>
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? "active" : ""}`}>
                  <a href="#">Quarterly</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? "on" : "off"}`}
                  >
                    <span className="slider round" />
                  </label>
                </li>
                <li
                  className={`year ${toggleSwitch === false ? "active" : ""}`}
                >
                  <a href="#">Annually</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tabed-content">
            {toggleSwitch ? (
              <div id="month">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Trial</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                          <p>/two week</p>
                        </div>
                        <p>Get 14 days free trial</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>14 Days Completely Free</li>
                          <li>Access all features in the "Single User" plan</li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "month",
                                true
                              )
                            }
                          >
                            {loadingCheckout.monthTrial ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="#00a0c6"
                              />
                            ) : (
                              "Activate Trial"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span
                          style={{
                            fontWeight: "600",
                            background:
                              "linear-gradient(90deg, rgba(0,232,255,1) 0%, rgba(255,0,254,1) 100%)",
                          }}
                        >
                          * Cyber Monday Deal *
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6>Single User</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>1,500</span>
                          <p>/quarter</p>
                        </div>
                        <p>Billed £1,500 per 3 months.</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "month"
                              )
                            }
                          >
                            {loadingCheckout.month ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Buy"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Enterprise</h6>
                        <p style={{ margin: "0" }}>Starts From</p>
                        <div className="price-range">
                          <sup>£</sup> <span>7,000</span>
                          <p>/quarter</p>
                        </div>
                        <p>Billed £7,000 per 3 months</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          href="#"
                          onClick={() => setDemoSelected(true)}
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div id="year">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Trial</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                          <p>/one week</p>
                        </div>
                        <p>Get 7 days free trial</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>7 Days Completely Free</li>
                          <li>Access all features in the "Single User" plan</li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "year",
                                true
                              )
                            }
                          >
                            {loadingCheckout.yearTrial ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="#00a0c6"
                              />
                            ) : (
                              "Activate Trial"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span
                          style={{
                            fontWeight: "600",
                            background:
                              "linear-gradient(90deg, rgba(0,232,255,1) 0%, rgba(255,0,254,1) 100%)",
                          }}
                        >
                          * Cyber Monday Deal *
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6>Single User</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>5,000</span>
                          <p>/yearly</p>
                        </div>
                        <p>Billed £5,000 annually.</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "year"
                              )
                            }
                          >
                            {loadingCheckout.year ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Buy"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Enterprise</h6>
                        <p style={{ margin: "0" }}>Starts From</p>
                        <div className="price-range">
                          <sup>£</sup> <span>25,000</span>
                          <p>/yearly</p>
                        </div>
                        <p>Billed £25,000 annually</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          href="#"
                          onClick={() => setDemoSelected(true)}
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p style={{ textAlign: "center", marginTop: "16px" }}>
              *International buyers, please note that no additional taxes will
              be applied to the purchase of any of the above licenses.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingHomeOne;
