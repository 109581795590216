import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  LinearProgress,
} from "@mui/material";
import styled from "styled-components";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PdfForm from "./PdfForm";

import getSymbolFromCurrency from "currency-symbol-map";
import { group_to_titles } from "./reportRenderer/helperFunctions";
import { prettierName, toNormalDecimal } from "../../common/helperFunctions";
import { BpGraph } from "../../BpGraph";

function CableList({ cables, previewPDF, includedSpecs, setIncludedSpecs }) {
  const [ordered, setOrdered] = useState([]);
  const bpOptions = new BpGraph();

  const formatUnits = (unit) => {
    let formatted = getSymbolFromCurrency(unit);

    if (!formatted) {
      if (unit !== "string" && unit !== "Str" && unit !== "-") {
        formatted = unit;
      } else {
        formatted = "";
      }
    }
    return formatted;
  };

  const showLoading = (loading) => {
    const loadingDivs = [];
    for (let i = 0; i < loading; i++) {
      loadingDivs.push(
        <LoadingBox key={`loading_bar_${i}`}>
          <LinearProgress />
        </LoadingBox>
      );
    }
    return loadingDivs;
  };

  const findProperty = (property) => {
    cables.forEach((cable) => {
      if (cable[property] !== undefined) return cable[property].screen_name;
    });

    return prettierName(property)
  };

  const cellWidth = 40;

  useEffect(() => {
    // const temp = [];

    // console.log("RE_ORDERED - Reorder list to be the same as BP graph");

    // for (let i = 0; i < cables.length; i++) {
    //   const groups = group_to_titles([cables[i]]);
    //   let orderedBy = { ...groups["Financial"], ...groups["input data"] };

    //   Object.keys(groups).map((group) => {
    //     if (
    //       group !== "Financial" &&
    //       group !== "input data" &&
    //       group !== "Internal" &&
    //       group !== "Helper"
    //     )
    //       orderedBy = { ...orderedBy, ...groups[group] };
    //   });

    //   temp.push(orderedBy);
    // }

    // setOrdered(temp);

    let temp = group_to_titles([cables[0]]);
    let orderedBy = [
      ...Object.keys(temp["Financial"]),
      ...Object.keys(temp["input data"]),
    ];

    Object.keys(temp).map((group) => {
      if (
        group !== "Financial" &&
        group !== "input data" &&
        group !== "Internal" &&
        group !== "Helper"
      )
        orderedBy.push(...Object.keys(temp[group]));
    });

    setIncludedSpecs(
      orderedBy.filter((spec) => includedSpecs.indexOf(spec) > -1)
    );
  }, [cables]);

  return (
    <CableListBox
      id="CableListBox"
      style={{
        left: previewPDF ? "999999px" : "0px",
      }}
    >
      <TableContainer
        component={Paper}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          left: "0px",
          top: "0px",
        }}
        elevation={0}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#5a5a5a",
                  fontWeight: "bold",
                  textAlign: "left",
                  minWidth: cellWidth,
                  whiteSpace: "nowrap",
                }}
              >
                Property
              </TableCell>
              {cables.map((_, index) => (
                <TableCell
                  key={index}
                  style={{
                    color: "#5a5a5a",
                    fontWeight: "bold",
                    textAlign: "left",
                    minWidth: cellWidth,
                    whiteSpace: "nowrap",
                  }}
                >
                  Cable {index + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cables.length > 0 &&
              // Object.keys(ordered[0]).map((key, kIndex) => {
              includedSpecs.map((key, kIndex) => {
                // if (includedSpecs.indexOf(key) !== -1) {
                return (
                  <TableRow key={kIndex}>
                    <TableCell
                      style={{
                        textAlign: "left",
                        minWidth: cellWidth,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* {cables[0][key] !== undefined
                        ? (cables[0][key].screen_name ? cables[0][key].screen_name : prettierName(key))
                        : prettierName(key)} */}
                      {/* {toNormalCase(key)} */}
                      {/* {prettierName(key)} */}
                      {findProperty(key)}
                    </TableCell>
                    {cables.map((cable, cIndex) => {
                      if (
                        cable[key] !== undefined &&
                        typeof cable[key][1] !== "object"
                      ) {
                        return (
                          <TableCell
                            key={cIndex}
                            style={{
                              textAlign: "left",
                              minWidth: cellWidth,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {toNormalDecimal(
                              cable[key].val,
                              key,
                              cable[key].units
                            ).replace("_", " ")}{" "}
                            {/*{formatUnits(cable[key][0].units)}*/}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={cIndex}
                            style={{
                              textAlign: "left",
                              minWidth: cellWidth,
                              whiteSpace: "nowrap",
                            }}
                          >
                            N/A
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
                // }
              })}
          </TableBody>
        </Table>
        {/* {showLoading(loading)} */}
      </TableContainer>
    </CableListBox>
  );
}

export default CableList;
const SettingsBox = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
const CableListBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 4px;
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  position: relative;
  /* overflow-x: scroll; */

  /* scrollbar-color: #009ec6 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }; */
`;

const LoadingBox = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;
`;
