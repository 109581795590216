import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Button, Box } from "@mui/material";
import Popover from "@mui/material/Popover";

const ColorPicker = ({ color, onColorChange, colorId, colorName }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDisplayColorPicker(false);
  };

  const handleChangeComplete = (color) => {
    onColorChange(colorId, color.hex);
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <Box
      width="55%"
      display="flex"
      alignItems="center"
      justifyContent="space-between" // This will place the color box on the right
    >
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        sx={{
          borderColor: color, // Use the provided color for border
          color: "#5a5a5a", // Text color is always #5a5a5a
          "&:hover": {
            borderColor: color, // Keep the border color on hover
            backgroundColor: `${color}20`, // Lighten color on hover
          },
          flex: 1, // Button will fill the space
          marginRight: "8px", // Margin right for spacing
        }}
      >
        {colorName}
      </Button>
      <Box
        width="24px"
        height="24px"
        bgcolor={color}
        border={1}
        borderColor="grey.300"
        // no marginRight needed here since the box is on the right
        display="inline-block"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
      </Popover>
    </Box>
  );
};

export default ColorPicker;
