import { Divider, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Datasheets from "./Datasheets";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Joyride, { STATUS } from 'react-joyride';

import DatasheetsTut from "../../media/tutorials/Datasheets.gif";
import DocumentStyles from "../../media/tutorials/Styles.gif";
import PDFPreview from "../../media/tutorials/PDF.gif";
import DocFilters from "../../media/tutorials/Filters.gif";
import { SecButton } from "../../styles/styles";
import DatasheetConfig from "./DatasheetConfig";

const Section = styled.div`
  position: relative;
  height: 100%;
  width: 65%;
  max-width: 65%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;

  h1 {
    margin: 0;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: rgba(255,255,255,0.5) !important;
    transform: scale(1.1);
  }
`;

function Results({ runSettings, toAnalyse, runAPI, setRunAPI, workspace, setWorkspace }) {
  const [runJoyride, setRunJoyride] = useState(false)
  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Cable Results</h2>
            <img width={"100%"} src={DatasheetsTut} />
            <p>View the full datasheet and the price of any analysed cables here. </p>
          </div>),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: 'left',
        target: '.section_results',
        disableBeacon: true
      },
      {
        content: (
          <div>
            <h2>Cable Costs</h2>
            <p><strong>The Geometric Method -</strong> uses the cable section design to get
              weight requirements of each constituent material which are matched to historical
              material prices to predict the overall price.<br /><br />
              <strong>The Neural Network Method -</strong> harnesses the power of artificial
              intelligence to correlate input features directly with historical material
              prices - it seeks patterns and relationships in the data to forecast prices.</p>
          </div>),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: 'left',
        target: '.section_results',
        disableBeacon: true
      },
      {
        title: "Results Filters",
        content:
          <div>
            <img width={"100%"} src={DocFilters} />
            <p>
              Tailor the datasheet output to only show what you care about. <br></br> Select from our pre-sets or toggle on/off any cable specification.
            </p>
          </div>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "top",
        target: "#output_settings"
      },
      {
        title: "Download CSV",
        content: (
          <div>
            <p>
              Download the above shown cable datasheet as a .csv file or as a "Power Factory" .xlsx input file.
            </p>
          </div>),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: 'top',
        target: '#download_csv',
      },
      {
        title: "Download PDF",
        content:
          <div>
            <p>
              Download the shown cable datasheet as a PDF file.
            </p>
          </div>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "top",
        target: "#download_pdf"
      },
      {
        title: "Report Theme",
        content:
          <div>
            <img width={"100%"} src={DocumentStyles} />
            <p>
              Easily customise the PDF document with basic metadata and company colours and logos.
            </p>
          </div>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "top-bottom",
        target: "#document_settings"
      },
      {
        title: "PDF Preview",
        content:
          <div>
            <img width={"100%"} src={PDFPreview} />
            <p>
              Get the best view of your cable datasheet with our PDF preview.
            </p>
          </div>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "top-bottom",
        target: "#preview_pdf"
      },
    ]
  });

  const [openSettings, setOpenSettings] = useState(false)
  const [preSetConfig, setPreSetConfig] = useState(null);
  const [includedSpecs, setIncludedSpecs] = useState([])

  const [proxyData, setProxyData] = useState([])

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false)
    }
  };

  const handleTutorial = () => {
    setRunJoyride(true)
  }

  const handleClose = () => {
    setOpenSettings(false);
  }

  useEffect(() => {
    if (proxyData.length === 0) {
      setPreSetConfig(null)
    }
  }, [proxyData])

  return (
    <>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
            // width: "fit-content",
          },
        }}
      />
      <Section id="section_results" className="section_results">
        <header >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h1>Results</h1>
              <StyledIconButton title="Results Guidance" onClick={handleTutorial}>
                <FontAwesomeIcon icon={faCircleQuestion} />
              </StyledIconButton>
            </div>
            <div>
              {/* <StyledIconButton title="Filter" onClick={handleFilter}> */}
              <SecButton id="output_settings" title="Result Filters" disabled={proxyData.length === 0} onClick={() => setOpenSettings(true)}>
                Filter
              </SecButton>
              {/* </StyledIconButton> */}
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "16px" }} />

        </header>
        <Datasheets workspace={workspace} setWorkspace={setWorkspace} toAnalyse={toAnalyse} runAPI={runAPI} includedSpecs={includedSpecs} setIncludedSpecs={setIncludedSpecs} proxyData={proxyData} setProxyData={setProxyData} />
      </Section>

      <Modal
        open={openSettings}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DatasheetConfig
          cablesSpecs={proxyData}
          handleClose={handleClose}
          includedSpecs={includedSpecs}
          setIncludedSpecs={setIncludedSpecs}
          preSetConfig={preSetConfig}
          setPreSetConfig={setPreSetConfig}
          workspace={workspace}
          setWorkspace={setWorkspace}
        />
      </Modal>
    </>
  )
}

export default Results