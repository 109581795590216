import React from "react";
// import { Link } from 'react-router-dom';
import logo from "../../../media/VEKTACABLES.png";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import VGBlack from "../assets/images/VG_black.png";
import VDBlack from "../assets/images/VD_black.png"

function FooterHomeThree({ className }) {
  const handleGetLink = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "admin/VektaCables_Ts&Cs.pdf"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "VektaCables_Ts&Cs.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <>
      <section className={`appie-footer-area appie-footer-3-area ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget footer-about-widget-3">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <p>Created by industry professionals for the industry!</p>
                <div className="social mt-30">
                  <ul style={{ display: "flex" }}>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/vekta-group/"
                      >
                        {/* <i
                          style={{
                            fontSize: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          class="fa-brands fa-linkedin fa-2xl"
                        ></i> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          height={32}
                        >
                          {/* <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                          <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        href="https://vektagroup.com/"
                      >
                        <img src={VGBlack} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        href="https://vektadigital.com/"
                      >
                        <img src={VDBlack} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              {/* <div className="footer-navigation footer-navigation-3">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Service">Service</Link>
                                    </li>
                                    <li>
                                        <a href="#">Case Studies</a>
                                    </li>
                                    <li>
                                        <Link to="/news">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div> */}
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation footer-navigation-3">
                <h4 className="title">Support</h4>
                <ul>
                  {/* <li>
                                        <Link to="/about-us">Community</Link>
                                    </li> */}
                  {/* <li>
                                        <a href="#">Resources</a>
                                    </li> */}
                  <li>
                    <a href="#faqs">Faqs</a>
                  </li>
                  <li>
                    <a href="https://vektagroup.com/privacy-policy/">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={handleGetLink}>
                      Terms and Conditions of Use
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="mailto:help@vektagroup.com">
                      <i className="fa-solid fa-envelope" /> help@vektagroup.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/7+Dundas+St,+Edinburgh+EH3+6QG"
                      target="_blank"
                    >
                      <i className="fa-solid fa-map-marker-alt" />7 Dundas
                      Street, Edinburgh, EH3 6QG
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
              >
                <div className="copyright-text">
                  <p>
                    Copyright © {new Date().getFullYear()} Vekta Group. All
                    rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeThree;