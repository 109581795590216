import React from 'react'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

function ContextMenu({ status, item, reset, sectionOne = [], sectionTwo = [], sectionThree = [], position }) {
  // const [settingsMenu, setSettingsMenu] = useState({ open: null, row: null })

  return (
    <Menu
      id="menu"
      getContentAnchorEl={null} // Prevents the menu from anchoring to a specific element
      anchorOrigin={{
        vertical: position.vertical1, // Vertical position for the anchor
        horizontal: position.horizontal1, // Horizontal position for the anchor
      }}
      transformOrigin={{
        vertical: position.vertical2, // Vertical position for the transform origin
        horizontal: position.horizontal2, // Horizontal position for the transform origin
      }}
      anchorEl={status.element} // Element to which the menu is anchored
      dense // Makes the menu items more compact
      keepMounted // Keeps the menu in the DOM even when closed
      open={status.open} // Controls whether the menu is open
      onClose={() => reset(item)} // Callback function when the menu is closed
      style={{ fontSize: "1rem" }} // Inline style for the menu
    >
      {sectionOne.map(item => {
        return (
          <MenuItem 
            style={{ fontFamily: "Montserrat", fontSize: "1rem" }} // Inline style for the menu item
            onClick={item.callback} // Callback function when the menu item is clicked
          >
            {item.name} 
          </MenuItem>
        )
      })}

      <Divider /> {/* Divider between section one and section two */}

      {sectionThree.map(item => {
        return (
          <MenuItem 
            style={{ fontFamily: "Montserrat", fontSize: "1rem" }} // Inline style for the menu item
            onClick={item.callback} // Callback function when the menu item is clicked
          >
            {item.name} 
          </MenuItem>
        )
      })}

      <Divider /> {/* Divider between section two and section three */}



{sectionTwo.map(item => {
        return (
          <MenuItem 
            style={{ fontFamily: "Montserrat", fontSize: "1rem" }} // Inline style for the menu item
            onClick={item.callback} // Callback function when the menu item is clicked
          >
            {item.name} 
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default ContextMenu