import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../../../firebase.js";

import { Box, Divider, Modal, Avatar, Badge } from '@mui/material'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as EditControl,
} from "@fortawesome/free-solid-svg-icons";
import {
  faComments as ChatIcon,
} from "@fortawesome/free-regular-svg-icons";

import { doc, updateDoc } from "firebase/firestore";

import IconButton from "@mui/material/IconButton";

import { InputText } from 'primereact/inputtext';

import { ModalClose, PrimeButton, SecButton, StyledIconButton } from "../../../styles/styles"

import Select from 'react-select';

import CloseIcon from '@mui/icons-material/Close';
import { sendPasswordResetEmail } from "firebase/auth";
import { getPortalUrl, getUserInfo } from "../helperFunctions.js";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const EditIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  background-color: ${(props) =>
    props.active ? "#007592" : "white"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;
  border: 2px solid #009ec6 !important;

  &:hover {
    background-color: "#007592" !important;
    transform: scale(1.1);
  }
`;

const StandardContainerColumn = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 16px;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  gap: 8px;
`;

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${props => props.success ? 'rgba(0, 196, 84, 1)' : 'rgb(206, 69, 69)'};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 20,
    bottom: 20,
  },
}));

// TODO - Get the user Account here

function UserProfile({ openProfile, setOpenProfile, setDemoSelected }) {
  const [user, loading, error] = useAuthState(auth);

  const [editInfo, setEditInfo] = useState({ personal: true, company: true });
  const [yourInfo, setYourInfo] = useState({ original: {}, info: {}, subscriptions: [], admin: false })
  const [emailSent, setEmailSent] = useState(false)

  const [manage, setManage] = useState(null);

  const defaultTeams = [
    { label: "Whole Company", value: "whole" },
    { label: "Electrical", value: "electrical" },
    { label: "GIS", value: "gis" },
    { label: "O&M", value: "om" },
    { label: "Project Management", value: "pm" }
  ]

  const orgTypes = [
    { value: "sme", label: "SME" },
    { value: "large_enterprise", label: "Large Enterprise" },
    { value: "uni", label: "University" },
    { value: "research", label: "Research Organization" },
    { value: "gov", label: "Government Agency" },
    { value: "non_profit", label: "Non-profit Organization" },
    { value: "other", label: "Other (Please Specify)" },
  ]

  const handleUserUpdate = (edit) => {
    if (JSON.stringify(yourInfo.original) !== JSON.stringify(yourInfo.info)) {
      console.log(yourInfo.info);
      updateDoc(doc(db, "user_accounts", user.uid), yourInfo.info).then(() => {
        setEditInfo(editInfo => ({ ...editInfo, [edit]: true }))
      })
    } else {
      setYourInfo(yourInfo => ({
        ...yourInfo,
        info: yourInfo.original,
      }))
      setEditInfo(editInfo => ({ ...editInfo, [edit]: true }))
    }
  }

  const handleDeleteAccount = () => {
    const confirm = window.confirm("You are about to permanently delete your account, Are you sure?");

    if (confirm) {
      setOpenProfile(false)
      auth.currentUser.delete();
      auth.signOut();
    }
  }

  const handleProfilePicture = () => {
    const handleImage = (e) => {
      const image = e.target.files[0];

      if (!image) return;

      const storageRef = ref(storage, `images/profile/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setYourInfo(yourInfo => ({
              ...yourInfo,
              info: {
                ...yourInfo.info,
                picture: downloadURL,
              },
              original: {
                ...yourInfo.original,
                picture: downloadURL,
              },
            }))

            await updateDoc(doc(db, "user_accounts", user.uid), {
              picture: downloadURL,
            });
          });
        }
      );
    }

    const a = document.createElement('input');

    a.id = "profile-image";
    a.type = "file";
    a.accept = "image/gif, image/jpeg, image/png";
    a.onchange = (e) => handleImage(e);
    a.click();
  }

  useEffect(() => {
    if (user) {
      getPortalUrl().then((url) => {
        console.log(url);
        setManage(url);
      });

      getUserInfo(user.uid).then((info) => {
        console.log(info);
        setYourInfo(yourInfo => ({
          ...yourInfo,
          info: info,
          original: info,
        }))
      })
    }
  }, [user]);

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 5000);
    }
  }, [emailSent])

  const [date, setDate] = useState(null);

  return (
    <Modal
      open={openProfile}
      onClose={() => setOpenProfile(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className='modal-popups'
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: "16px",
            position: "fixed",
            inset: "5% 5%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {emailSent && <InfoPrompt success={true} >Check your Email for password reset</InfoPrompt>}

          <header>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div></div>
              <ModalClose onClick={() => setOpenProfile(false)}>
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
          </header>

          <StandardContainerRow className="sidePanel_body" style={{ justifyContent: "normal", height: "100%" }}>
            <StandardContainerColumn style={{ width: "25%", minWidth: "25%", height: "100%", gap: "16px" }}>
              <StandardContainerColumn className="userAvatar" style={{ justifyContent: "center", background: "rgba(211, 211, 211, 0.3294117647)", borderRadius: "8px", height: "47%" }}>
                <h4>Profile</h4>
                <StyledBadge anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }} badgeContent={<EditIconButton onClick={handleProfilePicture}>
                  <FontAwesomeIcon size="xs" icon={EditControl} />
                </EditIconButton>}>

                  <Avatar sx={{ width: "7rem", height: "7rem", background: "#009ec6", border: "2px solid #009ec6" }} src={yourInfo.info?.picture && yourInfo.info?.picture}></Avatar>
                </StyledBadge>
                <h3 style={{
                  maxWidth: "80%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}>{(yourInfo.info?.firstname && yourInfo.info?.surname) ? yourInfo.info.firstname + " " + yourInfo.info.surname : user?.email}</h3>
              </StandardContainerColumn>

              <StandardContainerColumn className="userHelp" style={{ justifyContent: "center", background: "rgba(211, 211, 211, 0.3294117647)", borderRadius: "8px", height: "47%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 style={{ margin: "0 8px 0 0" }}>Need Help ?</h4>
                  <FontAwesomeIcon size="2xl" icon={ChatIcon} color="#009ec6" />
                </div>

                <p style={{ textAlign: "center", margin: "0" }}>
                  Have questions or concerns
                  regarding your Vekta Cables account
                  or the platform?
                  <br /><br />
                  Our Experts are here to help!
                </p>

                <SecButton style={{ width: "50%" }} onClick={() => setDemoSelected(true)}>Send Message</SecButton>
              </StandardContainerColumn>
            </StandardContainerColumn>

            <StandardContainerRow style={{ height: "100%" }}>
              <StandardContainerColumn style={{ height: "100%" }}>
                <StandardContainerRow style={{ padding: "8px" }}>
                  <h3 style={{ margin: "0 0 8px 0" }}>Personal Details</h3>
                  <StyledIconButton onClick={() => setEditInfo({ personal: false, company: true })}>
                    <FontAwesomeIcon size="xs" icon={EditControl} />
                  </StyledIconButton>
                </StandardContainerRow>

                <StandardContainerColumn style={{ height: "80%" }}>
                  <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                    <InputText disabled={editInfo.personal} id="userFirstname" style={{ width: "100%", height: "3rem", padding: "8px" }}
                      value={yourInfo?.info?.firstname}
                      onChange={(e) => setYourInfo(yourInfo => ({
                        ...yourInfo,
                        info: {
                          ...yourInfo.info,
                          firstname: e.target.value,
                        }
                      }))}
                    />
                    <label htmlFor="userFirstname">Firstname</label>
                  </span>
                  <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                    <InputText disabled={editInfo.personal} id="userSurname"
                      value={yourInfo?.info?.surname} style={{ width: "100%", height: "3rem", padding: "8px" }}
                      onChange={(e) => setYourInfo(yourInfo => ({
                        ...yourInfo,
                        info: {
                          ...yourInfo.info,
                          surname: e.target.value,
                        }
                      }))} />
                    <label htmlFor="userSurname">Surname</label>
                  </span>
                  {/* <span className="p-float-label" style={{ marginBottom: "16px", width: "85%", height: "3rem" }}>
                    <Calendar disabled={editInfo.personal} inputId="userBirthDate"
                      value={yourInfo.info?.birthday}
                      onChange={(e) => setYourInfo(yourInfo => ({
                        ...yourInfo,
                        info: {
                          ...yourInfo.info,
                          birthday: e.target.value,
                        }
                      }))} />
                    <label htmlFor="userBirthDate">Birth Date</label>
                  </span> */}
                  {/* <Calendar value={date} onChange={(e) => setDate(e.value)} /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "85%" }}
                      disabled={editInfo.personal}
                      label="Birth Date"
                      format="DD/MM/YYYY"
                      value={yourInfo.info?.birthday && dayjs(yourInfo.info.birthday)}
                      onChange={(e) => {
                        const day = e.$D;
                        const month = String(Number(e.$M) + 1).padStart(2, "0");
                        const year = e.$y;
                        const dateString = `${year}-${month}-${day}`;

                        setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            birthday: dateString,
                          }
                        }))
                      }} />
                  </LocalizationProvider>
                  <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                    <InputText disabled={true} id="userEmail"
                      value={yourInfo?.info?.email} style={{ width: "100%", height: "3rem", padding: "8px" }}
                    />
                    <label htmlFor="userEmail">Email</label>
                  </span>
                  <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                    <InputText disabled={editInfo.personal} id="userNumber" style={{ width: "100%", height: "3rem", padding: "8px" }}
                      value={yourInfo?.info?.contact}
                      onChange={(e) => setYourInfo(yourInfo => ({
                        ...yourInfo,
                        info: {
                          ...yourInfo.info,
                          contact: e.target.value,
                        }
                      }))} />
                    <label htmlFor="userNumber">Contact Number</label>
                  </span>
                </StandardContainerColumn>

                {!editInfo.personal && <PrimeButton style={{ width: "85%" }} onClick={() => handleUserUpdate("personal")}>
                  {JSON.stringify(yourInfo.original) !== JSON.stringify(yourInfo.info) ?
                    "Save Changes" :
                    "Cancel"
                  }
                </PrimeButton>}

              </StandardContainerColumn>

              <Divider orientation="vertical" flexItem />

              <StandardContainerColumn style={{ height: "100%" }}>
                <StandardContainerColumn style={{ height: "70%" }}>
                  <StandardContainerRow style={{ padding: "8px" }}>
                    <h3 style={{ margin: "0 0 8px 0" }}>Organisation Details</h3>
                    <StyledIconButton onClick={() => setEditInfo({ personal: true, company: false })}>
                      <FontAwesomeIcon size="xs" icon={EditControl} />
                    </StyledIconButton>
                  </StandardContainerRow>

                  <StandardContainerColumn style={{ height: "70%" }}>
                    <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                      <InputText disabled={editInfo.company} id="userOrgName"
                        style={{ width: "100%", height: "3em", fontFamily: "Montserrat", fontSize: "16px", padding: "8px" }}
                        value={yourInfo?.info?.orgname}
                        onChange={(e) => setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            orgname: e.target.value,
                          }
                        }))} />
                      <label htmlFor="userOrgName">Organisation Name</label>
                    </span>

                    <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                      <label htmlFor="userOrgType" style={{ top: "-10px" }}>Organisation Type</label>
                      <Select
                        id="userOrgType"
                        isDisabled={editInfo.company}
                        defaultValue={yourInfo.info?.orgtype ? yourInfo.info?.orgtype : orgTypes[0]}
                        isClearable
                        isSearchable
                        options={orgTypes}
                        styles={{ height: "4rem" }}
                        onChange={(e) => setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            orgtype: e,
                          }
                        }))}
                      />
                    </span>

                    <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                      <InputText disabled={editInfo.company} id="userOrgTitle" style={{ width: "100%", height: "3rem", padding: "8px" }}
                        value={yourInfo.info?.job}
                        onChange={(e) => setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            job: e.target.value,
                          }
                        }))} />
                      <label htmlFor="userOrgTitle">Job Title</label>
                    </span>

                    <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                      <InputText disabled={editInfo.company} id="userOrgreg" style={{ width: "100%", height: "3rem", padding: "8px" }}
                        value={yourInfo.info?.company_reg}
                        onChange={(e) => setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            company_reg: e.target.value,
                          }
                        }))} />
                      <label htmlFor="userOrgreg">Company Registration Number</label>
                    </span>

                    <span className="p-float-label" style={{ width: "85%", height: "3rem" }}>
                      <InputText disabled={editInfo.company} id="userOrgvat" style={{ width: "100%", height: "3rem", padding: "8px" }}
                        value={yourInfo.info?.vat_reg}
                        onChange={(e) => setYourInfo(yourInfo => ({
                          ...yourInfo,
                          info: {
                            ...yourInfo.info,
                            vat_reg: e.target.value,
                          }
                        }))} />
                      <label htmlFor="userOrgvat">VAT Registration Number</label>
                    </span>


                  </StandardContainerColumn>

                  {!editInfo.company && <PrimeButton style={{ width: "85%" }} onClick={() => handleUserUpdate("company")}>
                    {JSON.stringify(yourInfo.original) !== JSON.stringify(yourInfo.info) ?
                      "Save Changes" :
                      "Cancel"
                    }</PrimeButton>}
                </StandardContainerColumn>

                <Divider flexItem />

                <StandardContainerColumn style={{ height: "30%" }}>
                  <StandardContainerRow style={{ paddingLeft: "8px" }}>
                    <h3 style={{ margin: "0 0 8px 0" }}>Account Actions</h3>
                    <div></div>
                  </StandardContainerRow>

                  <div style={{ height: "60%", display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                    <SecButton onClick={() => {
                      sendPasswordResetEmail(auth, user.email).then(() => {
                        setEmailSent(true)
                      })
                    }}>Change Password</SecButton>
                    {/* onClick={() => window.location.href = subUrl} */}
                    <SecButton onClick={() => {
                      console.log(manage);
                      if (manage) window.location.href = manage
                    }}>Manage Subscription</SecButton>
                    <PrimeButton onClick={handleDeleteAccount}>Delete Account</PrimeButton>
                  </div>
                </StandardContainerColumn>
              </StandardContainerColumn>

            </StandardContainerRow>
          </StandardContainerRow>
        </Box>
      </div>
    </Modal>
  );
}

export default UserProfile;
