import React, {  } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase';
import { ApplyButton, PrimeButton, SecButton } from '../../../styles/styles';

function CommentTable({ data, setData, newRows, setNewRows, pendingDelete, setPendingDelete }) {
    const headers = data[0]; // First row is the header

    const handleEdit = (event, rowIndex, cellKey, isNewRow) => {
        const value = cellKey.toLowerCase() === 'status' ? event.target.checked : event.target.value;

        console.log(`Editing row ${rowIndex}, cell '${cellKey}', isNewRow: ${isNewRow}, value: ${value}`); // Debugging log

        const updatedRows = isNewRow ? [...newRows] : [...data];
        updatedRows[isNewRow ? rowIndex : rowIndex + 1][headers.indexOf(cellKey)] = value;

        isNewRow ? setNewRows(updatedRows) : setData(updatedRows);
    };

    const handleDelete = (rowIndex, isNewRow) => {
        if (isNewRow) {
            const updatedNewRows = [...newRows];
            updatedNewRows.splice(rowIndex, 1);
            setNewRows(updatedNewRows);
        } else {
            setPendingDelete(true);
            const updatedData = [...data];
            updatedData.splice(rowIndex + 1, 1); // rowIndex + 1 because of header
            setData(updatedData);
        }
    };

    const renderCell = (cell, cellIndex, rowIndex, isNewRow) => {
        const cellKey = headers[cellIndex];
        const handleChange = (event) => handleEdit(event, rowIndex, cellKey, isNewRow);

        if (cellKey.toLowerCase() === 'status') {
            return <Checkbox checked={!!cell} onChange={handleChange} />;
        } else if (cellKey.toLowerCase() === 'comment' || cellKey.toLowerCase() === 'response') {
            return (
                <TextField
                    value={cell}
                    onChange={handleChange}
                    multiline
                    rows={8}
                    fullWidth
                />
            );
        } else if (cellKey.toLowerCase() === 'delete') {
            return (
                <IconButton onClick={() => handleDelete(rowIndex, isNewRow)}>
                    <DeleteIcon />
                </IconButton>
            );
        } else {
            return <TextField value={cell} onChange={handleChange} />;
        }
    };


    return (
        <>
            <TableContainer component={Paper} style={{ height: "100%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => <TableCell style={{ fontSize: "1rem" }} key={index}>{header}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(1).map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {row.map((cell, cellIndex) => <TableCell style={{ alignItems: "center" }} key={cellIndex}>{renderCell(cell, cellIndex, rowIndex, false)}</TableCell>)}
                            </TableRow>
                        ))}
                        {newRows.map((row, rowIndex) => (
                            <TableRow key={`new-${rowIndex}`} style={{ backgroundColor: '#AFFFC7' }}>
                                {row.map((cell, cellIndex) => <TableCell key={cellIndex}>{renderCell(cell, cellIndex, rowIndex, true)}</TableCell>)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <SecButton onClick={downloadCSV} variant="contained" style={{ marginLeft: '10px', width: "fit-content" }}>
                    Download CSV
                </SecButton>
                <PrimeButton onClick={handleAddRow} variant="contained">
                    Add Row
                </PrimeButton>
                {pendingDelete &&
                    <div style={{ marginLeft: '20px', color: "red" }}>UNSAVED DELETES</div>}
            </div> */}

        </>
    );
}

export default CommentTable;
