import getSymbolFromCurrency from "currency-symbol-map";

export const prettierName = (name) => {
  // make sure name is a string
  if (typeof name !== "string") {
    return name;
  }

  // replace : and _ with space
  // remove metadata and settings and design from name
  name = name.replace(/metadata./g, "");
  name = name.replace(/settings./g, "");
  name = name.replace(/design./g, "");
  name = name.replace(/environment./g, "");
  name = name.replace(/cable./g, "");
  name = name.replace(/financial./g, "");
  name = name.replace(/[:_]/g, " ");
  return name.charAt(0).toUpperCase() + name.slice(1);
};

// export const toNormalDecimal = (val, key) => {
//     if (!val || val === "undefined") return "N/A"
//     // Helper function to convert a number to scientific notation if necessary with 2 decimal places
//     const convertToScientificNotation = (num) => {
//         if (Math.abs(num) >= 10000 || Math.abs(num) < 0.0001) {
//             return num.toExponential(2);
//         }
//         return num.toFixed(2);
//     };

//     if (typeof val === "string") {
//         // if val is an imaginary number
//         if (val.includes("+")) {
//             const splitVal = val.split("+");
//             const realPart = parseFloat(splitVal[0]);
//             const imaginaryPart = parseFloat(splitVal[1].split("j")[0]);

//             return convertToScientificNotation(realPart) + "+" + convertToScientificNotation(imaginaryPart) + "j";
//         }
//         return val.replace(/_/g, ' ');
//     } else if (typeof val === "number") {
//         return convertToScientificNotation(val);
//     }

//     // If the value is neither string nor number, return it as is
//     return val;
// };

export const toNormalDecimal = (val, key, units) => {
  //   console.log({ val, key, units });

  if (!val || val === "undefined") return "N/A";
  //   // Helper function to convert a number to scientific notation if necessary with 2 decimal places
  //   const convertToScientificNotation = (num, unit) => {
  //     console.log({ num, unit });
  //     switch (true) {
  //       case num >= 1000000000:
  //         return (num / 1000000000).toFixed(2) + "_G" + formatUnits(unit);

  //       case num >= 1000000 && num > 1000:
  //         const usemUnits =
  //           unit !== undefined && !unit.includes("M")
  //             ? "M" + formatUnits(unit)
  //             : formatUnits(unit);
  //         return (num / 1000000).toFixed(2) + "_" + usemUnits;

  //       case num >= 1000 && num > 0.001:
  //         const usekUnits =
  //           unit !== undefined && !unit.includes("k")
  //             ? "k" + formatUnits(unit)
  //             : formatUnits(unit);
  //         return (num / 1000).toFixed(2) + "_" + usekUnits;

  //       case num <= 0.001 && num > 0.00001:
  //         return (num * 1000).toPrecision(2) + "_m" + formatUnits(unit);

  //       case num <= 0.000001 && num > 0.00000001:
  //         return (num * 1000000).toPrecision(2) + "_μ" + formatUnits(unit);

  //       case num <= 0.000000001:
  //         return (num * 1000000000).toPrecision(2) + "_n" + formatUnits(unit);

  //       default:
  //         return num.toFixed(2) + "_" + formatUnits(unit);
  //     }
  //   };

  const formatUnits = (unit) => {
    let formatted = getSymbolFromCurrency(unit);

    if (!formatted) {
      if (
        unit !== "string" &&
        unit !== "Str" &&
        unit !== "-" &&
        unit !== "integer"
      ) {
        formatted = unit;
      } else {
        formatted = "";
      }
    }
    return formatted;
  };

  if (typeof val === "string") {
    if (val.includes("from " | "up to ")) {
      return val + "_" + formatUnits(units);
    }

    // if val is an imaginary number
    if (val.includes("+")) {
      const splitVal = val.split("+");
      const realPart = parseFloat(splitVal[0]);
      const imaginaryPart = parseFloat(splitVal[1].split("j")[0]);

      // return convertToScientificNotation(realPart, null) + "+" + convertToScientificNotation(imaginaryPart, null) + "j " + formatUnits(units);
      return (
        realPart.toExponential(2) +
        "+" +
        imaginaryPart.toExponential(2) +
        "j_" +
        formatUnits(units)
      );
    }

    return val.replace(/_/g, " ") + "_" + formatUnits(units);
  }
  //   else if (
  //     typeof val === "number" &&
  //     key !== "cost" &&
  //     key !== "nn_cost" &&
  //     units !== "A"
  //   ) {
  //     const useUnits =
  //       units !== undefined && !units.includes("mm") ? units : null;

  //     console.log(useUnits);

  //     return useUnits
  //       ? convertToScientificNotation(val, useUnits)
  //       : val.toFixed(2) + "_" + formatUnits(units);
  //   }

  // If the value is neither string nor number, return it as is
  return val.toFixed(2) + "_" + formatUnits(units);
};

// export const toNormalDecimal = (val, key) => {

//     if (typeof val === "string") {
//         // if val is an imaginary numbers
//         if (val.includes("+")) {
//             const splitVal = val.split("+")
//             return toNormalDecimal(parseFloat(splitVal[0])) + "+" + toNormalDecimal(parseFloat(splitVal[1].split("j")[0])) + "j"
//         }
//         return val.replace(/_/g, ' ');
//     }

//     // if (/\d/.test(val)) {
//     //   if (val.indexOf("+") > -1) {
//     //     const first = val.split("+")[0]
//     //     const second = val.split("+")[1].slice(0, -1)

//     //     return (Number(first) + Number(second)) + "j"
//     //   }

//     //   return Number(val)
//     // }

//     if (key === "cost" || key === "nn_cost") return parseFloat(val).toFixed(2);

//     if (val === 0) return 0;
//     // return string if string

//     // console.log(val.toExponential(2));
//     console.log(`${val.toExponential(2)} > ${parseInt(10).toExponential(2)}`);
//     console.log(val.toExponential(2) > Number(1e1));
//     // if val is number greater than 1e6, convert to scientific notation
//     if (Number(val.toExponential(2)) > Number(1e1)) return val.toExponential(2);
//     // if val is number less than 1e-3, convert to scientific notation
//     console.log(`${val.toExponential(2)} > ${parseInt(10).toExponential(2)}`);
//     console.log(val.toExponential(2) < parseFloat(0.1).toExponential(2));
//     if (Number(val.toExponential(2)) < Number(1e-1)) return val.toExponential(2);
//     // otherwise return val
//     return val.toFixed(2);
// }
