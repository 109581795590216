import React, { useEffect, useState } from "react";
import { Page } from "./ReportStyles";
import styled from "styled-components";
import section_url from "./Section.png";

const Front = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 416px;
`;
const HeaderBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 202px;
  min-height: 120px;
  width: 100%;
  background-color: ${(props) => props.bgColor};
`;
const LogoBox = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  height: 100%;
  width: 286px;

  border-bottom: 2px solid ${(props) => props.bgColor};
`;
const Logo = styled.img`
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  object-fit: cover;
  /* aspect-ratio: 1; */
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 48px;
  /* padding-left: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  font-size: 40px;
  /* padding-bottom: 20px; */
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-weight: 600;
`;
const HeaderName = styled.div`
  text-align: right;
  font-size: 20px;
  color: white;
`;

const TitleBar = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
`;
const TitleGap = styled.div`
  height: 10px;
  background-color: ${(props) => props.bgColor};
`;
const TitleName = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  display: flex; // Set the display to flex
  align-items: center; // Vertically center the content
  text-align: center;
  justify-content: center;
  color: white;
`;
const TitleRight = styled.div`
  width: 100%;
  opacity: 40%;
  background-color: ${(props) => props.bgColor};
`;

const Body = styled.div`
  /* height: calc(100% - 120px - 48px - 120px); */
  width: 731px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 16px;
  /* overflow: hidden; */
  position: relative;

  padding: 24px 16px;
  margin: auto;

  border: 2px solid ${(props) => props.bgColor};;
  border-radius: 8px;

  /* background-color: white; */
`;

const Section = styled.img`
  position: absolute;
  top: 10%;
  right: -64px;
  width: 100%;
  height: 88%;
  opacity: 0.5;
`;

const DetailsColumn = styled.div`
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: right;
  padding: 20px;
`;
const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const DetailName = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: start; // Align children to the right
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  font-weight: 400;
  color: black;
  /* min-height: 40px; */
`;

const DetailValue = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: flex-start; // Align children to the right: ;
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  min-height: 40px;
  word-wrap: break-word; // Ensure long words don't overflow
  display: block; // Use block to fill the width
  /* border: 1px solid lightgray; */
  justify-content: center;
  border-radius: 8px;
  font-weight: 800;
  color: black;
`;

const FooterBox = styled.div`
  position: relative;
  width: 731px;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  padding: 24px 16px;
  margin: auto;

  border: 2px solid ${(props) => props.bgColor};;
  border-radius: 8px;
`;
const FooterLeftBox = styled.div`
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  display: flex;
  width: 200px;
`;
const FooterMiddleBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0; // Make sure no padding is interfering
  margin-left: 8px;
`;

const FooterRightBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 0; // Make sure no padding is interfering
`;
const DocDetailName = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;

  color: black;
  font-weight: 500;
`;
const DocDetailValue = styled.div`
  display: flex;
  align-items: flex-start; // Align children to the right: ;
  box-sizing: border-box;
  text-align: left;
  justify-content: center;
  padding-left: 8px;
  border-radius: 0px 8px 8px 0;
  color: black;
`;

const MiddleShape = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: ${(props) => props.bgColor};
`;

const MiddleShape2 = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) translate(20px, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: white;
`;

function FrontPage({ logo, settings }) {
  const [color1, setColor1] = useState(settings.primaryColor);
  const [color2, setColor2] = useState(settings.secondaryColor);
  const [color3, setColor3] = useState(settings.tertiaryColor);

  useEffect(() => {
    setColor1(settings.primaryColor);
    setColor2(settings.secondaryColor);
    setColor3(settings.tertiaryColor);
  }, [settings.primaryColor, settings.secondaryColor, settings.tertiaryColor]);

  useEffect(() => {
    console.log(settings);
  }, [settings]);

  return (
    <Page>
      <Front>
        <Section src={section_url} />
        <HeaderBox bgColor={color1}>
          <LogoBox bgColor={color1}>
            <Logo src={logo.data ? logo.data : ""} alt="Company Logo" />
          </LogoBox>
          <Header>
            <HeaderTitle>CABLE DATASHEET</HeaderTitle>
          </Header>
        </HeaderBox>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "64px",
          }}
        >
          <Body bgColor={color1}>
            <label style={{ fontSize: "18px", fontWeight: "600" }}>
              Project Information
            </label>
            <div style={{ display: "flex", gap: "16px" }}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <Detail>
                  <DetailName>PROJECT</DetailName>
                  <DetailValue>{settings["Project"].value}</DetailValue>
                </Detail>
                <Detail>
                  <DetailName>CLIENT</DetailName>
                  <DetailValue>{settings["Client"].value}</DetailValue>
                </Detail>
              </div>
              <Detail
                style={{
                  width: "50%",
                }}
              >
                <DetailName>ADDITIONAL INFORMATION</DetailName>
                {/* <p
                  style={{
                    margin: "0px",
                    fontWeight: "800",
                    textAlign: "left",
                    color: "black",
                  }}
                >
                  The datasheet includes cable type, conductor material,
                  insulation, voltage rating, temperature range, and compliance
                  standards, detailing mechanical properties, electrical
                  performance, and applications for clarity in technical use.
                </p> */}
                <DetailValue>{settings["Additional Information"].value}</DetailValue>
              </Detail>
            </div>
          </Body>
          <FooterBox bgColor={color1}>
            <div style={{ display: "flex", width: "35%" }}>
              <DocDetailName bgColor={color1}>Date Of Issue:</DocDetailName>
              <DocDetailValue bgColor={color2}>
                {settings["Date"].value}
              </DocDetailValue>
            </div>

            <div style={{ display: "flex", width: "45%" }}>
              <DocDetailName bgColor={color1}>Document No.:</DocDetailName>
              <DocDetailValue bgColor={color2}>
                {settings["Document Number"].value}
              </DocDetailValue>
            </div>

            <div style={{ display: "flex", width: "20%" }}>
              <DocDetailName bgColor={color1}>Revision:</DocDetailName>
              <DocDetailValue bgColor={color2}>
                {settings["Revision"].value}
              </DocDetailValue>
            </div>
          </FooterBox>
        </div>
        <span
          style={{
            position: "absolute",
            width: "94%",
            left: "27px",
            bottom: "0",
            fontSize: "12px",
            color: "black",
            padding: "16px 32px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          Powered By&nbsp;
          <a href="https://vektagroup.com/" target="_blank">
            Vekta Group
          </a>
        </span>
      </Front>
    </Page>
  );
}

export default FrontPage;
