import styled from "styled-components";

export const Page = styled.div.attrs({ className: "pageClassName" })`
  width: 842px;
  /* width: 595px; */
  height: 1122px;
  /* height: 842px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  background-color: #fff;
  border: 1px solid #ccc;

  /* font-family: "calibri"; */
  color: black;

  box-sizing: border-box;
  overflow: hidden;
`;
